@mixin container {
	margin-left: auto;
	margin-right: auto;
	padding-left: 40px;
	padding-right: 40px;
}

@mixin scrollbar {
	/* width */
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background-color: #ebeef0;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #bfcace;
		border-radius: 10px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #2674b0;
	}
}
