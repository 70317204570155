@use 'src/styles/global-styles.scss' as *;
.success {
	border: 5px solid var(--color-navy);
	max-width: 690px;
	margin: 20px auto 0 auto;
	padding: 30px 20px;
	h3 {
		color: var(--color-navy);
		opacity: 0.8;
	}
}

.container {
	@include container;
	text-align: center;
	padding-top: 100px;
	padding-bottom: 100px;

	form {
		text-align: left;
		max-width: 690px;
		margin: 0 auto;
	}

	input,
	textarea {
		border: 1px solid var(--color-navy);
		border-radius: var(--border-radius);
		color: var(--color-navy);
		font-family: var(--font-lato);
		padding: 20px;
		width: 100%;
		margin-bottom: 20px;

		&::placeholder {
			color: var(--color-navy);
			opacity: 0.5;
		}
	}

	textarea {
		height: 160px;
	}

	label {
		display: block;
		width: 100%;
		margin: 10px 0;
		color: var(--color-navy);
		text-transform: uppercase;

		&.radio {
			font-family: var(--font-merry);
			text-transform: none;
			position: relative;
			cursor: pointer;
			font-size: 18px;
			user-select: none;
			padding-left: 35px;
			display: inline-block;
			margin-right: 30px;
			width: auto;

			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;
			}

			.checkmark {
				position: absolute;
				top: -1px;
				left: 0;
				height: 25px;
				width: 25px;
				background-color: var(--color-navy);
				opacity: 0.2;
				transition: var(--transition) opacity;
			}

			/* On mouse-over */
			&:hover input ~ .checkmark {
				opacity: 0.4;
			}

			/* When the radio button is checked, add a blue background */
			input:checked ~ .checkmark {
				opacity: 1;
			}

			/* Create the indicator (the dot/circle - hidden when not checked) */
			.checkmark:after {
				content: '';
				position: absolute;
				display: none;
			}

			/* Show the indicator (dot/circle) when checked */
			input:checked ~ .checkmark:after {
				display: block;
			}

			/* Style the indicator (dot/circle) */
			.checkmark:after {
				top: 9px;
				left: 9px;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: var(--color-white);
			}
		}
	}

	.radioWrapper {
		margin: 30px 0 40px 0;
	}

	.top {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: 740px;
		> div {
			margin: 20px;
			&:first-child {
				background-color: var(--color-navy);
				padding: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				h2 {
					color: var(--color-white);
					margin: 0;
					font-family: var(--font-merry);
					font-size: 50px;
					letter-spacing: 6px;
					border: 8px solid var(--color-white);
					padding: 40px;
				}
			}

			@include media($small-lowest, down) {
				margin: 20px 0;
			}
		}
	}

	button {
		border: 0;
		background-color: var(--color-navy);
		color: var(--color-white);
		text-transform: uppercase;
		padding: 15px 30px;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 1px;
		margin-top: 20px;
		transition: var(--transition) background-color;
		cursor: pointer;
		@include hover {
			background-color: var(--color-blue);
		}
	}

	.error {
		font-weight: bold;
		margin-top: 30px;
		color: Var(--color-peach);
	}
}
