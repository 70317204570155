@use './color' as *;
@use './font' as *;
@use './misc' as *;

:root {
	// ------------------------------------------------------ | Color variables
	@each $variable in $color {
		#{nth($variable, 1)}: #{nth($variable, 2)};
	}

	// ------------------------------------------------------ | Font variables
	@each $variable in $font {
		#{nth($variable, 1)}: #{nth($variable, 2)};
	}

	// ------------------------------------------------------ | Misc variables
	@each $variable in $misc {
		#{nth($variable, 1)}: #{nth($variable, 2)};
	}
}
