@use 'src/styles/global-styles.scss' as *;
.container {
	background-color: #eff0f3;
	> div {
		@include container;
		max-width: 900px;
		padding-top: 60px;
		padding-bottom: 120px;
	}
	h2 {
		color: var(--color-navy);
		font-family: var(--font-merry);
		text-align: center;
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 15px;
	}

	p {
		font-size: 20px;
		color: var(--color-navy);
		line-height: 30px;
		text-align: center;
		margin-top: 0;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}

	img {
		width: 100%;
		padding: 0 5px 30px 5px;
	}
}
