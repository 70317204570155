@use 'src/styles/global-styles.scss' as *;
.swiper-button-next,
.swiper-button-prev {
	color: var(--color-navy) !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	left: 0 !important;
	margin-top: -30px;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	right: 0 !important;
	margin-top: -30px;
}

.swiper {
	padding: 20px !important;
}

.swiper-pagination-bullet-active {
	background: var(--color-navy) !important;
}

// @media (max-width: 600px) {
// 	.swiper-button-next:after,
// 	.swiper-button-prev:after {
// 		font-size: 25px !important;
// 	}
// 	.swiper-button-next,
// 	.swiper-button-prev {
// 		width: calc(25px / 44 * 27) !important;
// 		height: 25px !important;
// 	}
// }
