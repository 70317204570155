// Media queries set variables
$smallest: 0;
$x-small-lowest: 360px;
$x-small: 400px;
$x-small-highest: 480px;
$small-lowest: 600px;
$small: 720px;
$small-highest: 840px;
$medium-lowest: 960px;
$medium: 1040px;
$medium-highest: 1140px;
$large-lowest: 1280px;
$large: 1440px;
$large-highest: 1600px;
$x-large-lowest: 1920px;
$x-large: 2560px;
$x-large-highest: 3360px;

// --------------------------------------------------------
// prettier-ignore

@mixin media($initial-width: false, $final-width: false) {
  @if type-of($initial-width) != number {
    @error '`#{$initial-width}` is not a valid value for $initial-width. `#{$examples}`';
  }
  @else if type-of($final-width) != number and $final-width != false and $final-width != down {
    @error '`#{$final-width}` is not a valid value for $final-width. `#{$examples}`';
  }
  @else if $initial-width and $final-width == false {
    @media only screen and (min-width: $initial-width) {
      @content;
    }
  }
  @else if $initial-width and $final-width == down {
    @media only screen and (max-width: $initial-width - 1) {
      @content;
    }
  }
  @else if $initial-width and type-of($final-width) == number {
    @media only screen and (min-width: $initial-width) and (max-width: $final-width - 1) {
      @content;
    }
  }
}
