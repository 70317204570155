@use 'variables' as *;
@use './mixins/font' as *;
@use './mixins/layout' as *;
@use './mixins/media' as *;
@use './mixins/reset' as *;
@use './mixins/support' as *;
@use './mixins/typography' as *;

@forward './mixins/font';
@forward './mixins/layout';
@forward './mixins/media';
@forward './mixins/reset';
@forward './mixins/support';
@forward './mixins/typography';

// --------------------------------------------------------

@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	@include font-smoothing;
	margin: 0;
	padding: 0;
	color: var(--color-black);
	font-family: var(--font-lato);
	font-size: 16px;
	line-height: 150%;
	background-color: var(--color-white);
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

h3 {
	font-family: var(--font-merry);
	margin: 10px 0;
	font-size: 22px;
	line-height: 160%;
}

h4 {
	font-family: var(--font-lato);
	text-transform: uppercase;
	font-weight: 300;
	color: var(--color-black);
	font-size: 16px;
	letter-spacing: 1px;
	margin: 10px 0;
}

html {
	scroll-behavior: smooth;
}
